<template>
    <div>
        <Loading v-if="$global.state.loading" />

        <div v-if="$global.state.chats">
            <!-- filters -->
            <v-card class="shadow-chat px-2 pt-1 d-flex justify-space-between align-center">
                <v-btn outlined @click="newContact" small text class="py-4">
                    <v-icon color="info">mdi-chat-plus</v-icon>
                </v-btn>

                <v-chip-group show-arrows mandatory color="info">
                    <v-chip
                        v-for="(item, index) in filtersChat"
                        :key="index"
                        label
                        outlined
                        filter
                        @click="setFiltersChat(item.value)"
                        class="py-4">
                        <h4 v-text="item.text"></h4>
                        <v-badge v-if="item.value == 2" dot overlap color="red">
                            <v-icon class="mr-2">fi fi-rr-envelope</v-icon>
                        </v-badge>
                        <v-icon v-else class="mr-2" v-text="item.icon"></v-icon>
                    </v-chip>
                </v-chip-group>
                <v-spacer></v-spacer>
                <v-text-field
                    v-debounce:500ms="getChats"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    hide-details
                    prepend-inner-icon="search"
                    dense
                    clearable
                    outlined
                    class="mr-2"
                    color="info"
                />
            </v-card>

            <v-card flat class="mt-3 pa-2" :class="$vuetify.theme.dark ? '' : 'chat-box'">
                <v-row no-gutters>
                    <!-- chats -->
                    <v-col cols="12" sm="3" class="pl-1">
                        <div class="chat-scroll">
                            <h4 class="text-center mt-10" v-if="$global.state.chats == ''">
                                <div v-if="$global.state.loading">جاري التحميل ...</div>
                                <span v-if="!$global.state.loading && $global.state.unReadMessages">لا توجد رسائل غير مقروءة</span>
                                <span v-if="$global.state.isPending">لا توجد رسائل معلقة</span>
                            </h4>
                            <div id="chatId" v-for="(item, index) in $global.state.chats" :key="index" class="rounded-lg ml-2" :class="$vuetify.theme.dark ? '' : 'white'">
                                <v-list-item
                                    @click="getMessagesSelectedChat(item, index)"
                                    color="white"
                                    class="border-card mb-1"
                                    :class="selectedChat.id == item.id ? !$vuetify.theme.dark ? 'active-chat' : 'active-chat-dark' : '' ">
                                    <v-list-item-icon>
                                        <v-avatar v-if="item.fromUser.imageUrl" size="36">
                                            <v-img :src="item.fromUser.imageUrl" alt="zaid" width="90"></v-img>
                                        </v-avatar>
                                        <v-avatar
                                            v-else
                                            size="36"
                                            color="primary"
                                            class="white--text">
                                            <span>
                                                {{
                                                item.fromUser.fullName
                                                .split(" ")[0]
                                                .charAt(0)
                                                }}
                                            </span>
                                            <span>
                                                {{
                                                item.fromUser.fullName.split(
                                                " "
                                                )[1]
                                                ? item.fromUser.fullName
                                                .split(" ")[1]
                                                .charAt(0)
                                                : ""
                                                }}
                                            </span>
                                        </v-avatar>
                                        <v-badge v-if="item.unreadMessagesCount > 0" overlap bordered color="warning lighten-1" :content="item.unreadMessagesCount"></v-badge>
                                    </v-list-item-icon>

                                    <v-list-item-content class="mr-n2">
                                        <v-list-item-title>
                                            <h4>{{ item.fromUser.fullName }}</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="d-flex justify-space-between">
                                                <v-rating color="primary" background-color="grey lighten-1" length="5" dense readonly x-small :value="item.fromUser.rating">
                                                    {{ }}
                                                </v-rating>
                                                <div>
                                                    <v-icon x-small color="primary darken-1"> fi fi-sr-shopping-cart </v-icon>
                                                    {{ item.fromUser.ordersCount }}
                                                </div>
                                            </div>
                                            <span class="font-small">
                                                <span v-if="item.fromUser.tab != null">
                                                    {{ item.fromUser.tab.name }}
                                                    -
                                                </span>
                                                {{ item.fromUser.province != null ? item.fromUser.province.name : "" }}
                                                -
                                                {{ item.fromUser.addres ? item.fromUser.addres.substring(0, 15) + " ..." : item.fromUser.addres }}
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </div>
                    </v-col>

                    <!-- messages -->
                    <v-col cols="12" sm="8" class="pl-1">
                        <v-card class="border-card" height="100%">
                            <!-- header chat -->
                            <div v-if="selectedChat != ''">
                                <div class="d-flex justify-space-between px-3 pt-2 pb-1" :class="$vuetify.theme.dark ? 'grey darken-4 rounded-t-lg' : 'rounded-t-xl white'">
                                    <div>
                                        <h4>{{ selectedChat.fromUser.fullName }}</h4>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="mt-1">
                                            <h6 class="ml-2">اخر ظهور</h6>
                                            <div class="font-small green--text">
                                                <v-icon x-small color="green">mdi-circle-small</v-icon>
                                                <!-- نشط -->
                                            </div>
                                        </div>
                                        <div>
                                            <v-avatar
                                                v-if="selectedChat.fromUser.imageUrl"
                                                size="36">
                                                <v-img
                                                    :src="selectedChat.fromUser.imageUrl"
                                                    alt="zaid"
                                                ></v-img>
                                            </v-avatar>
                                            <v-avatar
                                                v-else
                                                size="36"
                                                color="primary"
                                                class="white--text"
                                            >
                                                <span>{{ selectedChat.fromUser.fullName.split(" ")[0].charAt(0) }}</span>
                                                <span>{{ selectedChat.fromUser.fullName.split(" ")[1] ? selectedChat.fromUser.fullName.split(" ")[1].charAt(0) : "" }}</span>
                                            </v-avatar>
                                        </div>
                                    </div>
                                </div>
                                <v-card flat tile class="grey" height="2"></v-card>
                            </div>

                            <!-- no chats -->
                            <h4 class="text-center grey--text mt-16" v-show="selectedChat == ''"> <v-icon x-large>fi fi-rr-shield-exclamation</v-icon> <p class="mt-2">اختر محادثة</p> </h4>

                            <!-- messags-box -->
                            <div class="messags-box pa-4" v-if="selectedChat" v-on:scroll="handleScroll" :class=" $vuetify.theme.dark ? 'msger-chat-dark' : 'msger-chat'">
                                
                                <!-- no messages  -->
                                <h4 class="text-center grey--text mt-16"
                                    v-show="selectedChat && $global.state.messages == '' ">
                                    <v-icon x-large>fi fi-rr-shield-exclamation</v-icon>
                                    <p class="mt-2">لا توجد رســائل</p>
                                </h4>

                                <div v-for="(message, index) in $global.state.messages" :key="index" class="d-flex justify-space-between">

                                    <!-- reigth message -->
                                    <div class="message-width">
                                        <div v-if="message.senderUser.group.key != 2" class="mb-2">

                                            <div v-if="message.type == 1">
                                                <div class="font-small mb-1" :class="$vuetify.theme.dark ? '' : 'grey--text'"> {{ message.senderUser.fullName }} </div>
                                                <div :class="$vuetify.theme.dark ? 'message-box-dark' : 'message-box' " v-text="message.content"></div>
                                            </div>

                                            <div v-if="message.type == 2">
                                                <v-avatar size="250" class="grey lighten-2" rounded v-for="(attach, index) in message.attachments" :key="index">
                                                    <v-img
                                                        @click="(attachments = attach), (openOverlayAttach = true)"
                                                        :src="attach.path"
                                                        :alt="attach.name"
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </v-avatar>
                                            </div>

                                            <div v-if="message.type == 3" class="my-2">
                                                <div class="mb-2" rounded v-for="(attach, index) in message.attachments" :key="index + 'audio'">
                                                    <audio controls>
                                                        <source :src="attach.path" type="audio/ogg" />
                                                    </audio>
                                                </div>
                                            </div>

                                            <div dir="ltr" class="text-left mt-1 font-small" :class="$vuetify.theme.dark ? '' : 'grey--text'">
                                                <v-icon small :color="message.isRead  ? 'green' : '' ">{{ message.isRead ? 'mdi-check-all' : 'mdi-check' }}</v-icon>
                                                {{ message.created | timeSince }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- left message -->
                                    <div class="message-width">
                                        <div v-if="message.senderUser.group.key == 2" class="mb-6">
                                            
                                            <div v-if="message.type == 1">
                                                <div class="message-box" :class="$vuetify.theme.dark ? 'message-partner-dark' : 'message-partner'">{{ message.content }}</div>
                                            </div>

                                            <div v-if="message.type == 2">
                                                <v-avatar size="250" class="grey lighten-2" rounded v-for="(attach, index) in message.attachments" :key="index">
                                                    <v-img
                                                        @click="(attachments = attach), (openOverlayAttach = true)"
                                                        :src="attach.path"
                                                        :alt="attach.name"
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </v-avatar>
                                            </div>

                                            <div v-if="message.type == 3" class="my-2">
                                                <div class="mb-2" rounded v-for="(attach, index) in message.attachments" :key="index + 'audio'">
                                                    <audio controls>
                                                        <source :src="attach.path" type="audio/ogg" />
                                                    </audio>
                                                </div>
                                            </div>

                                            <div dir="ltr" class="text-left mt-1 font-small" :class="$vuetify.theme.dark ? '' : 'grey--text'">
                                                {{ message.created | timeSince }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-btn v-show="!scrollTopBtn" class="btn-last-messages border-card rounded-circle" small fab @click="updateScrollMsgbox">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </div>

                            <!-- inputs -->
                            <div v-if="selectedChat" class="chat-input pa-2 pt-5">
                                <div class="d-flex justify-space-between">
                                    <v-textarea
                                        outlined
                                        auto-grow
                                        dense
                                        max-width="500px"
                                        filled
                                        v-model="content"
                                        placeholder="اكتب رسالتك"
                                        rows="1"
                                        hide-details="auto"
                                        color="secondary"
                                        required
                                        @keydown.enter.exact.prevent="
                                            sendMessage
                                        "
                                        @keydown.enter.shift.exact.prevent="
                                            content += '\n'
                                        "
                                        class="message-input"
                                    >
                                        <template v-slot:prepend class="float-right">
                                            <v-btn
                                                v-show="content"
                                                icon
                                                class="mt-n1"
                                                @click="clikSendMessage"
                                                :loading="$global.state.loading"
                                            >
                                                <v-icon color="indigo">
                                                    fa-solid
                                                    fa-paper-plane
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-textarea>

                                    <v-btn @click="openSendVoiceDialog()" class="mr-2" color="green" x-small fab depressed>
                                        <v-icon color="white">fa-solid fa-microphone</v-icon>
                                    </v-btn>

                                    <v-btn @click="openSendImageDialog()" class="mr-2" color="info" x-small fab>
                                        <v-icon color="white" small>fa-solid fa-paperclip</v-icon>
                                    </v-btn>

                                    <v-btn @click="openClientTabsDialog(selectedChat)" class="mr-2" color="primary" x-small fab>
                                        <v-icon color="secondary" small>mdi-tag-multiple</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <!-- readyResponses -->
                    <v-col cols="12" sm="1">
                        <v-card flat class="border-card pa-2">
                            <div v-for="(item, index) in $global.state.readyResponses" :key="index">
                                <v-card
                                    color="primary"
                                    class="btn text-center mb-2 pa-4"
                                    @click="content = item.content"
                                >
                                    <h5 class="secondary--text">{{ item.title }}</h5>
                                </v-card>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <!-- overlay Attach -->
            <v-overlay z-index="1" :value="openOverlayAttach" opacity=".95">
                <v-btn
                    class="overlay-attach-btn"
                    v-click-outside="onClickOutside"
                    elevation="24"
                    @click="openOverlayAttach = false" icon>
                    <v-icon large>close</v-icon>
                </v-btn>
                <img
                    @click="openOverlayAttach = false"
                    class="overlay-attach-img"
                    :src="attachments.path"
                    :alt="attachments.name"
                />
            </v-overlay>
        </div>

        <Dialog />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Pusher from "pusher-js";

export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            benched: 0,
            selectedChat: "",
            selectedChatIndex: 0,
            scrollTopBtn: true,
            content: "",
            item: {},
            attachments: {},
            openOverlayAttach: false,
            filtersChat: [
                {
                    text: "كل الرسائل ",
                    value: 1,
                    icon: "fi fi-rr-envelope",
                },
                {
                    text: "غير المقروءة",
                    value: 2,
                    icon: "fi fi-rr-envelope",
                },
                {
                    text: "معلق",
                    value: 3,
                    icon: "fi fi-rr-hourglass-end",
                },
                {
                    text: "الاعدادات",
                    value: 4,
                    icon: "fi fi-rr-settings",
                },
            ],
        };
    },

    computed: {
        ...mapState("auth", ["userData"]),
        items() {
            return Array.from({ length: this.length }, (k, v) => v + 1);
        },
        length() {
            return 7000;
        },
    },

    async mounted() {
        // Pusher.logToConsole = true;
        this.pusher = new Pusher("750f63f9cc7b3ff4586c", {
            cluster: "us3",
        });
        this.channel = this.pusher.subscribe(
            "01messagesShamsAlghareeChannelSecret"
        );

        await this.getChats();
        this.$global.state.filter.pageSize = 50;
        this.getReadyResponses();

        this.$eventBus.$on(`updateScrollMsgbox`, () => {
            this.updateScrollMsgbox();
            if (this.selectedChatIndex != 0)
                this.updateIndexChats(this.$global.state.chats, this.selectedChat.id, 0);
        });

        this.$eventBus.$on(`refreshChats`, (chat) => {
            // this.getChats();
            console.log("refreshChats", chat);
            this.updateIndexChats(this.$global.state.chats, chat.id, 0);
            this.getMessagesSelectedChat(chat, 0);
        });

        window.scrollTo(0, document.body.scrollHeight);
    },
    

    beforeDestroy() {
        this.$eventBus.$off(`updateScrollMsgbox`);
        this.$eventBus.$off(`refreshChats`);
        this.channel.unbind();
    },

    methods: {
        async getMessagesSelectedChat(chat, index) {
            this.$global.state.filter.pageIndex = 1;
            this.selectedChatIndex = index;
            this.selectedChat = chat;
            this.$global.state.chatId = chat.id;
            await this.$global.dispatch(`getMessages`);
            this.updateScrollMsgbox();

            this.channel.unbind(chat.id);
            this.realTime(chat.id);

            console.log(chat)

            // update unread old Messages Chat
            var foundIndex = this.$global.state.chats.findIndex((x) => x.id == chat.id);
            this.$global.state.chats[foundIndex].unreadMessagesCount = 0;
        },

        async getChats() {
            await this.$global.dispatch(`getChats`);
            this.$global.state.chats.forEach((element) => {
                this.realTime(element.id);
            });
        },

        async getPreviousMessages() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(
                    `Messages?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&chatId=${this.selectedChat.id}&IsDelete=false`
                );
                const messages = this.$global.state.messages;
                this.$global.state.messages = res.data.result.data
                    .reverse()
                    .concat(messages);
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.$global.state.loading = false;
            }
        },

        async sendMessage(event) {
            // if (event.keyCode == 13 && event.shiftKey)
            if (this.content) {
                if (event.keyCode == 13 && !event.shiftKey) {
                    await this.$global.state.messages.push({
                        created: new Date().toUTCString(),
                        content: this.content,
                        type: 1,
                        senderUser: {
                            id: this.userData.userData.id,
                            group: this.userData.userData.group,
                        },
                    });
                    this.sendMessageToServer();
                    this.content = "";
                }
                this.updateScrollMsgbox();
                if (this.selectedChatIndex != 0)
                    this.updateIndexChats(
                        this.$global.state.chats,
                        this.selectedChat.id,
                        0
                    );
            }
        },

        async clikSendMessage() {
            await this.$global.state.messages.push({
                created: new Date().toUTCString(),
                content: this.content,
                type: 1,
                senderUser: {
                    id: this.userData.userData.id,
                    group: this.userData.userData.group,
                },
            });
            this.sendMessageToServer();
            this.content = "";
            this.updateScrollMsgbox();
            if (this.selectedChatIndex != 0)
                this.updateIndexChats(
                    this.$global.state.chats,
                    this.selectedChat.id,
                    0
                );
        },

        async sendMessageToServer() {
            this.item.chatId = this.selectedChat.id;
            this.item.content = this.content;
            this.item.type = 1;
            this.item.attachments = null;
            await this.$http
                .post(`Messages`, this.item)
                .then((res) => {})
                .catch((err) => {
                    console.log(err);
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async updateScrollMsgbox() {
            let msgbox = document.querySelector(".messags-box");
            msgbox.scrollTop = msgbox.scrollHeight; // <-- This does the trick
            this.scrollTopBtn = true;
        },

        async handleScroll(event) {
            let msgbox = document.querySelector(".messags-box");
            if (msgbox.scrollTop <= 0) {
                this.$global.state.filter.pageIndex++;
                let oldScrollHeight = msgbox.scrollHeight;
                await this.getPreviousMessages();
                let newScrollHeight = msgbox.scrollHeight;
                let y = newScrollHeight - oldScrollHeight;
                msgbox.scrollTo(0, y);
            }

            // console.log("scrollHeight =>", msgbox.scrollHeight);
            // console.log("scrollTop => ", msgbox.scrollTop);
            // console.log("height =>", msgbox.offsetHeight);

            if(msgbox.scrollHeight - 500 > (msgbox.offsetHeight + msgbox.scrollTop)) this.scrollTopBtn = false;
            else this.scrollTopBtn = true;
        },

        async realTime(eventName) {
            // this.channel.unbind(eventName);
            this.channel.bind(eventName, (data) => {
                // console.log("realTime ==> ", data);
                if (data != null && data != "updateMessagesToSeen") {
                    if (this.userData.userData.id != data.senderUser.id) {
                        if (this.selectedChat.id == data.chatId) {
                            this.seenMessages();
                            this.$global.state.messages.push(data);
                            setTimeout(() => {
                                this.updateScrollMsgbox();
                            }, 5);
                        } else {
                            console.log("update unreadMessagesCount");
                            this.updateUnreadMessagesCountChats(data.chatId);
                        }
                        this.updateIndexChats(
                            this.$global.state.chats,
                            data.chatId,
                            0
                        );
                    }
                } else {
                    if (data == "updateMessagesToSeen") {
                        this.seenMessages();
                        this.$forceUpdate();
                        console.log("updateMessagesToSeen");
                    }
                }
            });
        },

        seenMessages() {
            this.$global.state.messages.forEach((element) => {
                element.isRead = true;
            });
        },

        updateIndexChats(arr, chatId, new_index) {
            var old_index = arr.findIndex((x) => x.id == chatId);
            if (old_index != new_index) {
                if (new_index >= arr.length) {
                    var k = new_index - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
                this.selectedChatIndex = 0;
                return arr; // for testing
            }
        },

        async updateUnreadMessagesCountChats(chatId) {
            var foundIndex = this.$global.state.chats.findIndex(
                (x) => x.id == chatId
            );
            this.$global.state.chats[foundIndex].unreadMessagesCount++;

            // add amission to unreadMessagesCount of chats
            let msgbox = document.getElementById("chatId");
            msgbox.classList.add("chat-anim");
            setTimeout(() => {
                msgbox.classList.remove("chat-anim");
            }, 1300);
        },

        getReadyResponses() {
            this.$global.dispatch(`getReadyResponses`);
        },

        openSendImageDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openSendVoiceDialog() {
            var item = {
                dialogType: "voice",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openClientTabsDialog(selectedChat) {
            var item = {
                dialogType: "clientTabs",
                userId: selectedChat.fromUser.id,
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        newContact() {
            var item = {
                dialogType: "newContact",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },

        setFiltersChat(value) {
            if (value == 4) {
                this.$router.push("readyResponses");
            } else {
                if (value == 1) {
                    this.$global.state.unReadMessages = "";
                    this.$global.state.isPending = "";
                }
                if (value == 2) {
                    this.$global.state.unReadMessages = true;
                    this.$global.state.isPending = "";
                }
                if (value == 3) {
                    this.$global.state.unReadMessages = "";
                    this.$global.state.isPending = true;
                }
                this.$global.dispatch(`getChats`);
            }
        },

        onClickOutside() {
            this.openOverlayAttach = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay-attach-img {
    max-width: 100%;
    max-height: 90vh;
    cursor: pointer;
    background-attachment: fixed;
}
.overlay-attach-btn {
    position: absolute;
    top: 0;
    left: 0;
}
.btn-last-messages {
    position: absolute;
    bottom: 100px;
    z-index: 1;
}

.chat-box {
    background: #e7e8ea;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}

.chat-scroll {
    height: 90vh;
    width: 100%;
    overflow-y: auto;
}

.chat-anim {
    animation: anim 0.5s;
}

@keyframes anim {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(2px);
    }
    50% {
        transform: translateY(-5px);
    }
    75% {
        transform: translateY(5px);
    }
}

.messags-box {
    height: 77vh;
    width: 100%;
    overflow-y: scroll;
    // overflow: auto;
    padding-bottom: 30px !important;
}

.msger-chat {
    background-image: url(~@/assets/img/333.png);
    background-color: #fcfcfe;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.msger-chat-dark {
    background-image: url(~@/assets/img/121212125.jpg);
    // background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.shadow-chat {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
}

.active-chat {
    background: #e7e8ea;
    
}
.active-chat-dark { 
    background: #000000;
}

.message-width {
    max-width: 60%;
}

.message-box {
    padding: 6px 10px;
    border-radius: 6px 0 6px 0;
    position: relative;
    background: rgba(100, 170, 0, 0.1);
    border: 2px solid rgba(100, 170, 0, 0.1);
    color: #6c6c6c;
    white-space: pre-line;
}

.message-box:after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid rgba(100, 170, 0, 0.2);
    border-right: none;
    bottom: -22px;
    right: 10px;
}

.message-box-dark {
    padding: 6px 10px;
    border-radius: 6px 0 6px 0;
    position: relative;
    background: #009688;
    border: 2px solid #00887b;
    white-space: pre-line;
}

.message-box-dark:after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid #009688;
    border-right: none;
    bottom: -22px;
    right: 10px;
}


.message-partner {
    background: rgba(0, 114, 135, 0.1);
    border: 2px solid rgba(0, 114, 135, 0.1);
    align-self: flex-start;
}

.message-partner:after {
    right: auto;
    bottom: auto;
    top: -22px;
    left: 9px;
    border: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 114, 135, 0.2);
    border-left: none;
}

.message-partner-dark {
    color: #e7e8ea;
    background: #1f608cbe;
    border: 2px solid #023f7d;
    align-self: flex-start;
}

.message-partner-dark:after {
    right: auto;
    bottom: auto;
    top: -22px;
    left: 9px;
    border: 10px solid transparent;
    border-bottom: 10px solid #1f618c;
    border-left: none;
}

</style>
